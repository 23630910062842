// React
import React from 'react';

// Design
import { ThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme.js';

// Internal
import StatusVMSTable from './Pages/StatusVMS';
import API from './Pages/API';
import StatusUsersTable from './Pages/Users';
import { persistor } from './store';
import { appUser } from './store/app/slice';
import SignIn from './Pages/Signin';
import EnvironmentsManagement from './Pages/EnvironmentsManagement';
import AppsManagement from './Pages/AppsManagement/index.js';

// Third-party
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { useSelector } from 'react-redux';

function App() {
  const user = useSelector(appUser);
  const userIsAllowed = Boolean(user);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route
              path={'/status'}
              element={
                userIsAllowed ? <StatusVMSTable /> : <Navigate to={`/`} />
              }
            />
            <Route
              path={'/users'}
              element={
                userIsAllowed ? <StatusUsersTable /> : <Navigate to={`/`} />
              }
            />
            <Route
              path={'/environments-management'}
              element={
                userIsAllowed ? (
                  <EnvironmentsManagement />
                ) : (
                  <Navigate to={`/`} />
                )
              }
            />
            <Route
              path={'/apps-management'}
              element={
                userIsAllowed ? <AppsManagement /> : <Navigate to={`/`} />
              }
            />

            <Route path={'/api'} element={<API />} />
            <Route
              path={'/'}
              element={userIsAllowed ? <Navigate to={`/status`} /> : <SignIn />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  );
}

export default App;
