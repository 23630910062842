import React, { useState, useEffect } from 'react';
import axios from 'axios';

const App = () => {
  const [selectedTool, setSelectedTool] = useState('api');
  return (
    <div style={{ display: 'flex', height: '100vh', fontFamily: 'sans-serif' }}>
      <div
        style={{
          flex: 1,
          padding: '20px',
          borderRight: '1px solid #ccc',
          overflowY: 'auto',
        }}
      >
        <h1>Clickservices API Testes</h1>
        <iframe
          src="/docs/api.html"
          title="API HTML Documentation"
          style={{ width: '100%', height: '90%' }}
        ></iframe>
      </div>
      {/* Right side: Tools */}
      <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
        {/* Tool menu */}
        <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
          <button
            onClick={() => setSelectedTool('api')}
            style={{
              flex: 1,
              background: selectedTool === 'api' ? 'lightblue' : 'white',
            }}
          >
            API
          </button>
          <button
            onClick={() => setSelectedTool('csv')}
            style={{
              flex: 1,
              background: selectedTool === 'csv' ? 'lightblue' : 'white',
            }}
          >
            CSV → JSON
          </button>
          <button
            onClick={() => setSelectedTool('base64')}
            style={{
              flex: 1,
              background: selectedTool === 'base64' ? 'lightblue' : 'white',
            }}
          >
            Arquivo → Base64
          </button>
        </div>

        {/* Tool panel */}
        <div style={{ flex: 1, padding: '16px' }}>
          <div className="flex-1 overflow-auto">
            {selectedTool === 'api' && <ApiTool />}
            {selectedTool === 'csv' && <CsvToJsonTool />}
            {selectedTool === 'base64' && <FileToBase64Tool />}
          </div>
        </div>
      </div>
    </div>
  );
};

function ApiTool() {
  const [method, setMethod] = useState('GET');
  const [url, setUrl] = useState('');
  const [token, setToken] = useState('');
  const [body, setBody] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [jsonError, setJsonError] = useState(null);

  const sendRequest = async () => {
    setError(null);
    setResponse(null);

    let parsedBody = undefined;
    if (body) {
      try {
        parsedBody = JSON.parse(body);
        setJsonError(null);
      } catch (e) {
        setJsonError(e.message);
        return;
      }
    }

    try {
      const config = {
        method,
        url,
        headers: {
          'Content-Type': 'application/json',
          ...(token && { Authorization: `Bearer ${token}` }),
        },
        ...(parsedBody ? { data: parsedBody } : {}),
      };

      const res = await axios(config);
      setResponse(res);
    } catch (err) {
      setError(err.message || 'Error occurred');
    }
  };
  return (
    <div>
      <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
        <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
          <select value={method} onChange={(e) => setMethod(e.target.value)}>
            {['GET', 'POST', 'PUT', 'DELETE', 'PATCH'].map((m) => (
              <option key={m}>{m}</option>
            ))}
          </select>

          <input
            type="text"
            placeholder="URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            style={{ flex: 1 }}
          />
        </div>

        <input
          type="text"
          placeholder="Bearer Token (opcional)"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          style={{ width: '100%', marginBottom: '10px' }}
        />

        <div>
          <textarea
            placeholder="Corpo do Request (JSON) - opcional"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            rows={8}
            style={{
              width: '100%',
              marginBottom: '10px',
              fontFamily: 'monospace',
            }}
          />
          {jsonError && (
            <div style={{ color: 'red', whiteSpace: 'pre-wrap' }}>
              {jsonError}
            </div>
          )}
        </div>

        <button
          onClick={sendRequest}
          style={{ padding: '10px 20px', marginBottom: '20px' }}
        >
          Enviar Request
        </button>

        <div>
          {error && (
            <div style={{ color: 'red', whiteSpace: 'pre-wrap' }}>{error}</div>
          )}

          {response && (
            <div>
              <h2>Response</h2>
              <div>
                Status: {response.status} {response.statusText}
              </div>
              <pre
                style={{
                  background: '#f4f4f4',
                  padding: '10px',
                  overflowX: 'auto',
                }}
              >
                {JSON.stringify(response.data, null, 2)}
              </pre>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function CsvToJsonTool() {
  const [delimiter, setDelimiter] = useState(',');
  const [jsonOutput, setJsonOutput] = useState('');
  const [columns, setColumns] = useState([]);
  const [originalColumns, setOriginalColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split(/\r?\n/).filter((line) => line.trim() !== '');
      if (lines.length === 0) return;

      const header = lines[0].split(delimiter);
      setColumns([...header]);
      setOriginalColumns([...header]);

      const parsedRows = lines.slice(1).map((line) => line.split(delimiter));
      setRows(parsedRows);

      const json = parsedRows.map((row) => {
        const obj = {};
        header.forEach((key, index) => {
          obj[key] = row[index];
        });
        return obj;
      });
      setJsonOutput(JSON.stringify(json, null, 2));
    };
    reader.readAsText(file);
  };

  const handleColumnChange = (index, newName) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = newName;
    setColumns(updatedColumns);

    const updatedJson = rows.map((row) => {
      const obj = {};
      updatedColumns.forEach((key, idx) => {
        obj[key] = row[idx];
      });
      return obj;
    });
    setJsonOutput(JSON.stringify(updatedJson, null, 2));
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <label>1. Selecione o Delimitador: </label>
        <select
          value={delimiter}
          onChange={(e) => setDelimiter(e.target.value)}
        >
          <option value=",">Vírgula (,)</option>
          <option value=";">Ponto e Vírgula (;)</option>
          <option value="\t">Tab (\t)</option>
          <option value="|">Pipe (|)</option>
        </select>
      </div>
      2. &nbsp;
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      {columns.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <h3>Edite o nome das colunas</h3>
          {columns.map((col, idx) => (
            <div key={idx}>
              <label>
                <strong>{originalColumns[idx]}</strong> →
                <input
                  type="text"
                  value={col}
                  onChange={(e) => handleColumnChange(idx, e.target.value)}
                  style={{ marginLeft: '10px' }}
                />
              </label>
            </div>
          ))}
        </div>
      )}
      <div style={{ marginTop: '20px' }}>
        <h3>JSON</h3>
        <textarea rows="15" cols="80" value={jsonOutput} readOnly />
      </div>
    </div>
  );
}

function FileToBase64Tool() {
  const [base64Output, setBase64Output] = useState('');
  const [metadata, setMetadata] = useState({});

  const humanFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) +
      ' ' +
      ['B', 'KB', 'MB', 'GB', 'TB'][i]
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result.split(',')[1];
      setBase64Output(base64);

      setMetadata({
        name: file.name,
        extension: file.name.split('.').pop(),
        size: humanFileSize(file.size),
        type: file.type,
      });
    };
    reader.readAsDataURL(file);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(base64Output);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />

      <div style={{ marginTop: '20px' }}>
        <button onClick={copyToClipboard}>Copy Base64</button>
      </div>

      <div style={{ marginTop: '10px' }}>
        <h3>Base64</h3>
        <textarea rows="10" cols="80" value={base64Output} readOnly />
      </div>

      <div style={{ marginTop: '10px' }}>
        <h3>Metadado</h3>
        <textarea
          rows="6"
          cols="80"
          value={JSON.stringify(metadata, null, 2)}
          readOnly
        />
      </div>
    </div>
  );
}

export default App;
